<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">后台管理系统</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 全屏显示 -->
                <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen ? `取消全屏` : `全屏`" placement="bottom">
                        <i class="el-icon-rank"></i>
                    </el-tooltip>
                </div>
                <!-- 消息中心 -->
                <div>
                    <!-- <el-tooltip effect="dark" :content="message ? `有${message}条未读消息` : `消息中心`" placement="bottom">
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span> -->

                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link"> {{ currentTenant.name }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="changeTenant(item)" :key="index" v-for="(item, index) in tenantList">{{
                                item.name
                            }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img src="../../assets/img/img.jpg" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ userInfo.name }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!--   <el-dropdown-item divided>项目仓库</el-dropdown-item>
                       -->
                        <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>
import bus from '../common/bus';
import Config from '@/utils/config';
import Cache from '@/utils/cache';
export default {
    data() {
        return {
            collapse: false,
            fullscreen: false,
            name: 'linxin',
            message: 2,
            tenantList: [], //租户列表
            app_id: 10, // 3 爱购  5商城
            currentTenant: {}, //当前租户
            userInfo: {}
        };
    },
    computed: {
        username() {
            let username = localStorage.getItem('ms_username');
            return username ? username : this.name;
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == 'loginout') {
                //     localStorage.removeItem('ms_username');
                //     this.$router.push('/login');
                this.quitFun();
            }
        },
        // 侧边栏折叠
        collapseChage() {
            this.collapse = !this.collapse;
            bus.$emit('collapse', this.collapse);
        },
        // 全屏事件
        handleFullScreen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },

        // //租户列表
        // getbyUser() {
        //     this.$store.dispatch('getbyUser', {}).then(res => {
        //         let arr = res;
        //         arr.forEach(element => {
        //             if (element.app_id == this.app_id && element.permission) {
        //                 this.tenantList.push(element);
        //             }
        //         });
        //         let tenant = Cache.get(Config.currentTenant);
        //         if (tenant) {
        //             //没有租户默认选第一个
        //             this.currentTenant = tenant;
        //         } else {
        //             this.currentTenant = this.tenantList[0];
        //         }
        //         Cache.set(Config.currentTenant, this.currentTenant);
        //         this.notifyMenu(); //通知改变菜单
        //     });

        //     // this.$store.dispatch('getSignature', { dir: 'test' }).then((res) => {});
        // },
        //租户列表
        getbyUser() {
            this.$store.dispatch('getbyUser', {}).then(res => {
                console.log('res33',res)
                res.forEach(element => {
                    if (element.app_id == this.app_id && element.permission) {
                        this.tenantList.push(element);
                    }
                });
                setTimeout(() =>{
                    console.log('租户列表',this.tenantList)
                    if(this.tenantList.length<=0) return
                    if((Cache.get('currentTenant') == undefined || Cache.get('currentTenant') == null || Cache.get('currentTenant').length == 0) || Cache.get('currentTenant') === '{}'){
                        this.currentTenant = this.tenantList[0];
                    }else if(Cache.get('currentTenant') && JSON.stringify(Cache.get('currentTenant')) != '{}'){
                        this.currentTenant = Cache.get('currentTenant');
                    } 
                    this.notifyMenu(); //通知改变菜单
                })                                     
            });

            // this.$store.dispatch('getSignature', { dir: 'test' }).then((res) => {});
        },
        changeTenant(item) {
            // console.info(item)
            Cache.set(Config.currentTenant, item);
            this.currentTenant = item;
            this.notifyMenu(); //通知改变菜单
            bus.$emit('tag_clear_all'); //清除标签
        },

        // 退出
        quitFun() {
            console.info('xx');
            // this.oidcVarible.mgr.signoutRedirect();

            this.$alert(`是否退出`, ' ', {
                customClass: 'quiteAlert',
                confirmButtonText: '确定',
                closeOnClickModal: true,
                roundButton: true
            })
                .then(() => {
                    const quitFunLoading = this.$loading({
                        lock: true,
                        text: 'Loading'
                    });
                    setTimeout(() => {
                        quitFunLoading.close();
                        this.oidcVarible.mgr.signoutRedirect();
                    }, 2000);
                })
                .catch(action => {
                    quitFunLoading.close();
                });
        },

        getUserInfo(userJson) {
            console.info(userJson);
            if (userJson.access_token && userJson.access_token != 'undefined') {
                Cache.setStr(Config.accessToken, 'Bearer ' + userJson.access_token);
            }
            Cache.set(Config.userInfo, userJson.profile);
            this.userInfo = userJson.profile;
            this.getbyUser();
        },
        //通知更改菜单数据
        notifyMenu() {
            Cache.set(Config.currentTenant, this.currentTenant);
            bus.$emit('changeTanantMenu', this.currentTenant);
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
        // this.quitFun()
        this.oidcVarible.requestBefore(this.getUserInfo);
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo {
    float: left;
    width: 250px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
    margin-top: 7px;
    margin-right: 10px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
