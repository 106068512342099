<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="(item,index) in menuList">
                <template v-if="item.children">
                    <el-submenu :index="item.module_id+''" :key="item.module_id">
                        <template slot="title">
                            <i class="el-icon-eleme"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <template v-for="subItem in item.children">
                            <el-submenu v-if="subItem.children" :index="subItem.url+''" :key="subItem.module_id">
                                <template slot="title">{{ subItem.name }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.children" :key="i" :index="threeItem.url+''">{{
                                        threeItem.name
                                    }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.url+''" :key="subItem.module_id">{{ subItem.name }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.url+''" :key="item.module_id">
                        <i class="el-icon-tickets"></i>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
import cache from '@/utils/cache';
export default {
    data() {
        return {
            collapse: false,
            menuList: []
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    created() {
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', msg => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    mounted() {
        console.info(this.$store.state.dictionary.customerValue);
        bus.$on('changeTanantMenu', msg => {
            console.info(msg);
            this.getMenu();
            this.getCommonValues();
        });
    },
    methods: {
        getMenu() {
            this.$store.dispatch('getMenu', {}).then(res => {
                let home = { name: '系统主页', module_id: '00001', url: '/home' };
                this.menuList = res;
                // this.menuList.unshift(home);
            });
        },
        getCommonValues() {
            this.$store.dispatch('getDict', 'countrys').then(res => {});
            this.$store.dispatch('getDict', 'platform').then(res => {});
            this.$store.dispatch('getDict', 'lang').then(res => {});
            this.$store.dispatch('getDict', 'country_ot').then(res => {});
            this.$store.dispatch('getDict', 'banner_position').then(res => {});
            this.$store.dispatch('getDict', 'brand').then(res => {});
        }
    },
    destroyed() {
        bus.$off('changeTanantMenu', {});
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
